/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import Link from "next/link"
import { Disclosure } from "@headlessui/react"

const solutions = [
  {
    name: "Substack",
    description: "Learn why users choose Paragraph over Substack.",
    href: "/vs-substack",
  },
  {
    name: "Mirror",
    description: "Learn why users choose Paragraph over Mirror.",
    href: "/vs-mirror",
  },
  {
    name: "Medium",
    description: "Learn why users choose Paragraph over Medium.",
    href: "/vs-medium",
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export default function WhyParagraphMenu(props: {
  openClassNames: string
  closeClassNames: string
}) {
  return (
    <>
      <Popover className="relative hidden md:block">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? props.openClassNames : props.closeClassNames,
                "group rounded-md inline-flex items-center text-sm px-2 py-1 hover:bg-slate-100 hover:text-slate-900 focus:outline-none "
              )}
            >
              <span>Why Paragraph?</span>
              <ChevronDownIcon
                className={classNames(
                  open ? "text-slate-600" : "text-slate-400",
                  "ml-2 h-5 w-5 group-hover:text-slate-500"
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <p className="mt-1 text-sm text-slate-500 border-b border-slate-200 pb-3">
                      Learn what sets Paragraph apart.
                    </p>
                    {solutions.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        prefetch={false}
                        legacyBehavior
                      >
                        <span className="-m-3 p-3 block rounded-md hover:bg-slate-50 transition ease-in-out duration-150 cursor-pointer">
                          <p className="text-base font-medium text-slate-900">
                            Paragraph vs. {item.name}
                          </p>
                          {/* 
                      <p className="mt-1 text-sm text-slate-500">
                        {item.description}
                      </p>
                         */}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      <Disclosure as="div" className="space-y-1 block md:hidden">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                "text-slate-900 hover:bg-slate-50 hover:text-slate-900",
                "group w-full flex items-center pr-2 py-2 text-left text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              )}
            >
              <svg
                className={classNames(
                  open ? "text-slate-400 rotate-90" : "text-slate-300",
                  "mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-slate-400 transition-colors ease-in-out duration-150"
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
              Why Paragraph?
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {solutions.map((subItem) => (
                <Disclosure.Button
                  key={subItem.name}
                  as="a"
                  href={subItem.href}
                  className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-slate-600 rounded-md hover:text-slate-900 hover:bg-slate-50"
                >
                  Paragraph vs {subItem.name}
                </Disclosure.Button>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
