import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid"

import { Button } from "components/Landing2/Button"

const features = [
  {
    name: "Share a Paragraph link",
    description:
      "Share any Paragraph link - a newsletter, a post, or even this page - with your wallet address in the URL.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Get paid for helping creators growth",
    description:
      "Whenever your audience clicks on your link and collects an NFT, you get paid immediately.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Get paid for helping Paragraph grow",
    description:
      "If your audience creates a new account on Paragraph and begins creating content, you'll get an indefinite cut of their revenue.",
    href: "#",
    icon: ArrowPathIcon,
  },
]

export default function Overview(props: { showMoreButton?: boolean }) {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-sm font-semibold leading-7 text-blue-600 uppercase tracking-widest">
            REFERRALS RE-IMAGINED
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            A better referral program
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            All Paragraph publications have a built-in referral program: any
            reader can share any content, and get paid for helping drive growth
            for creators and growth for Paragraph.
          </p>

          {props.showMoreButton && (
            <Button className="mt-8" href="/referral-rewards">
              <span className="text-lg">Learn more</span>
            </Button>
          )}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature, idx) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <div className="rounded-full bg-blue-100 py-2 px-3 text-blue-600 leading-none">
                    {idx + 1}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
