import Link from "next/link"

import ImageLogoSvg from "components/ImageLogoSvg"

import { tabs } from "components/Discover/DiscoverTags"
import FarcasterIcon from "components/icons/Farcaster"

const footerNavigation = {
  main: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Accessibility", href: "#" },
    { name: "Partners", href: "#" },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/paragraph_xyz",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },

    {
      name: "Warpcast",
      href: "https://warpcast.com/paragraph",
      icon: (props: any) => (
        <FarcasterIcon className="w-6 h-6 fill-current text-gray-500 grayscale opacity-80 hover:opacity-100 hover:grayscale-0" />
      ),
    },

    {
      name: "Paragraph",
      href: "https://paragraph.xyz/@blog",
      icon: (props: any) => <ImageLogoSvg {...props} />,
    },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-2xl px-4 py-12 mx-auto overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center mt-8 space-x-6">
          {footerNavigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-500 hover:text-gray-300"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-6 h-6" aria-hidden="true" />
            </a>
          ))}
        </div>

        <div className="grid grid-cols-2 justify-center mt-8 space-x-6 text-gray-500">
          <div className="flex flex-col text-center">
            <Link href={`/privacy`} className="hover:text-gray-300">
              Privacy Policy
            </Link>
            <Link href={`/content-guidelines`} className="hover:text-gray-300">
              Content Guidelines
            </Link>

            <Link href={`/terms-of-use`} className="hover:text-gray-300">
              Terms of Use
            </Link>
          </div>
          <div className="flex flex-col text-center">
            <Link href="/careers" className="hover:text-gray-300">
              Careers
            </Link>
            <a
              className="hover:text-gray-300"
              href="https://feedback.paragraph.xyz"
            >
              Feature Requests & Feedback
            </a>
          </div>
        </div>
        <p className="mt-8 text-base text-center text-gray-500">
          &copy; 2023 Paragraph Technologies Inc. All rights reserved. <br />
          Questions, feedback, or just want to chat? Email us at{" "}
          <a
            className="text-blue-500 underline"
            href="mailto:hello@paragraph.xyz"
          >
            hello@paragraph.xyz
          </a>
          , or{" "}
          <a
            href="https://discord.gg/ZRqz7V5vdq"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline"
          >
            join our community on Discord
          </a>
          .
        </p>

        <p className="mt-8 text-base text-center text-gray-500">
          Discover the best writers on Paragraph.
        </p>

        <div className="grid grid-flow-col grid-rows-5 auto-cols-max justify-center mt-8 text-gray-500 text-left ">
          {Array.from(tabs).map((tab) => (
            <div key={tab.name} className="mx-4">
              <Link
                href={`https://paragraph.xyz/discover/feed/trending/paragraph/${
                  tab.url || ""
                }`}
                className="hover:text-gray-300"
              >
                {tab.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}
