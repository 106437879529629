import Ticker from "framer-motion-ticker"
import Image from "next/image"

import Allen from "public/ticker/allen_taylor.jpg"
import nfa from "public/ticker/nfa.png"
import w3a from "public/ticker/w3a.jpg"
import sixninesix from "public/ticker/696.jpg"
import farquest from "public/ticker/farquest.jpg"
import tbp from "public/ticker/tbp.jpg"
import exmachina from "public/ticker/exmachina.jpg"
import chris from "public/ticker/chris.jpg"
import tokenpad from "public/ticker/tokenpad.jpg"
import pop from "public/ticker/pop.jpg"
import danica from "public/ticker/danica.jpg"
import outcaster from "public/ticker/outcaster.jpg"
import dave from "public/ticker/dave.jpg"
import poetry from "public/ticker/poetry.jpg"
import rug from "public/ticker/rug.jpg"

import mosaic from "public/ticker/mosaic.jpg"
import sapiens from "public/ticker/sapiens.jpg"
import vrypan from "public/ticker/vrypan.jpg"
import limone from "public/ticker/limone.jpg"
import web3law from "public/ticker/web3law.jpg"
import gcr from "public/ticker/gcr.jpg"
import kiwi from "public/ticker/kiwi-updates.jpg"
import router from "public/ticker/router.jpg"

import pinksink from "public/ticker/pinksink.jpg"
import andrey from "public/ticker/andreydidovskiy.jpg"
import w3adao from "public/ticker/w3adao.jpg"
import seanbonner from "public/ticker/seanbonner.jpg"
import sfermion from "public/ticker/sfermion.jpg"
import wibtal from "public/ticker/wibtal.jpg"
import defi_voyager from "public/ticker/defi_voyager.jpg"
import tinkering_society from "public/ticker/tinkering_society.jpg"
import lesgreys from "public/ticker/lesgreys.jpg"
import teleyinex from "public/ticker/teleyinex.jpg"
import ensdao from "public/ticker/ens_dao.jpg"
import epicdylan from "public/ticker/epicdylan.jpg"
import backdrop from "public/ticker/backdrop.jpg"
import lxry from "public/ticker/lxry.jpg"

import { StaticImageData } from "next/legacy/image"
import { useState } from "react"

type User = {
  src: StaticImageData
  url: string
  name: string
}

const users: User[] = [
  {
    src: Allen,
    url: "/@tayloredcontent",
    name: "Allen Taylor",
  },
  {
    src: nfa,
    url: "/@nfa",
    name: "Not F'ing Around",
  },
  {
    src: w3a,
    url: "/@web3academy?utm_medium=referral&utm_source=paragraph",
    name: "Web3 Academy",
  },
  {
    src: farquest,
    url: "/@farquest",
    name: "Farquest",
  },
  {
    src: tbp,
    url: "/@thebiggerpicture",
    name: "The Bigger Picture",
  },
  {
    src: exmachina,
    url: "/@exmachina",
    name: "Ex Machina",
  },
  {
    src: chris,
    url: "/@cocreated",
    name: "Chris Co-Created",
  },
  {
    src: pop,
    url: "/@pop5",
    name: "Pop 5",
  },

  {
    src: danica,
    url: "/@danicaswanson",
    name: "Danica Swanson",
  },
  {
    src: tokenpad,
    url: "/@tokenpad",
    name: "Tokenpad",
  },
  {
    src: outcaster,
    url: "/@outcasters",
    name: "Outcasters",
  },
  {
    src: poetry,
    url: "/@onchainpoetry",
    name: "On-Chain Poetry Digest",
  },
  {
    src: dave,
    url: "/@davc_s",
    name: "Dave Swinbank",
  },
  {
    src: rug,
    url: "/@therugnews",
    name: "The Rug News",
  },
]

const usersSecond: User[] = [
  {
    src: mosaic,
    url: "/@OnchainAlpha",
    name: "On-Chain Alpha",
  },
  {
    src: sapiens,
    url: "/@cryptosapiens",
    name: "Crypto Sapiens",
  },

  {
    src: sixninesix,
    url: "/@696eth",
    name: "696.eth",
  },
  {
    src: vrypan,
    url: "/@purplesubmarine",
    name: "Purple Submarine",
  },
  {
    src: limone,
    url: "/@limone.eth",
    name: "Limone",
  },
  {
    src: web3law,
    url: "/@web3legal",
    name: "Web3 Legal",
  },
  {
    src: gcr,
    url: "/@globalcoinresearch",
    name: "GCR",
  },
  {
    src: kiwi,
    url: "/@kiwi-updates",
    name: "Kiwi News",
  },

  {
    src: router,
    url: "/@routerprotocol",
    name: "Router Protocol",
  },

  {
    src: ensdao,
    url: "/@ensdao",
    name: "ENS DAO",
  },
  {
    src: epicdylan,
    url: "/@tdylandanielphilosophy",
    name: "Epic Dylan",
  },
]

const usersThird: User[] = [
  {
    src: pinksink,
    url: "/@pinksink",
    name: "Pink Sink",
  },
  {
    src: andrey,
    url: "/@andreydidovskiy",
    name: "Andrey Didovskiy",
  },
  {
    src: w3adao,
    url: "/@w3adao",
    name: "Web3Academy DAO",
  },
  {
    src: seanbonner,
    url: "/@seanbonner",
    name: "Sean Bonner",
  },
  {
    src: lxry,
    url: "/@lxryhotel",
    name: "LXRY Hotel",
  },
  {
    src: sfermion,
    url: "/@sfermions-collider",
    name: "Sfermion",
  },
  {
    src: wibtal,
    url: "/@limes.eth",
    name: "What I've Been Thinking About Lately",
  },
  {
    src: defi_voyager,
    url: "/@defi_voyager",
    name: "DeFi Voyager",
  },

  {
    src: tinkering_society,
    url: "/@tinkering-society",
    name: "Tinkering Society",
  },

  {
    src: lesgreys,
    url: "/@lesgreys",
    name: "Les Greys",
  },
  {
    src: teleyinex,
    url: "/@teleyinex",
    name: "Teleyinex",
  },

  {
    src: backdrop,
    url: "/@backdrop",
    name: "Backdrop",
  },
]

function TickerImage(props: { user: User; i: number }) {
  return (
    <a href={props.user.url} target="_blank" rel="noreferrer">
      <div
        className="group flex flex-col justify-center text-center py-4 relative
      hover:cursor-pointer


      "
      >
        <Image
          key={props.i}
          src={props.user.src}
          alt={props.user.name}
          className="w-48 px-4 group-hover:blur-sm group-hover:opacity-20 transition-all cursor-pointer"
          sizes="160px"
        />
        <div
          className="group-hover:block hidden text-slate-800 text-xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        group-hover:cursor-pointer

        "
        >
          {props.user.name}
        </div>
      </div>
    </a>
  )
}

function TickerComponent() {
  const [isPaused, setIsPaused] = useState<number | undefined>()

  return (
    <div className="">
      <Ticker
        duration={25}
        onMouseEnter={() => setIsPaused(0)}
        onMouseLeave={() => setIsPaused(undefined)}
        isPlaying={isPaused !== 0}
      >
        {users.map((user, i) => (
          <TickerImage key={i} user={user} i={i} />
        ))}
      </Ticker>

      <Ticker
        duration={30}
        direction={1}
        onMouseEnter={() => setIsPaused(1)}
        onMouseLeave={() => setIsPaused(undefined)}
        isPlaying={isPaused !== 1}
      >
        {usersSecond.map((user, i) => (
          <TickerImage key={i} user={user} i={i} />
        ))}
      </Ticker>

      <Ticker
        duration={16}
        onMouseEnter={() => setIsPaused(2)}
        onMouseLeave={() => setIsPaused(undefined)}
        isPlaying={isPaused !== 2}
      >
        {usersThird.map((user, i) => (
          <TickerImage key={i} user={user} i={i} />
        ))}
      </Ticker>
    </div>
  )
}
export default TickerComponent
