import React from "react"
import Image from "next/legacy/image"
//import q from "../public/feather_new_v2.png"
//import q from "../public/logo.png"
//import LogoText from "../public/branding/logo_with_text.svg"
import LogoText from "../components/media/LogoWithText"
import Link from "next/link"

interface Props {
  className?: string
  customUrl?: string
  width?: number
  height?: number
  href?: string
  color?: "black" | "white"
}

function _Logo({
  color,
  className,
}: {
  color?: "black" | "white"
  className?: string
}) {
  return (
    <LogoText color={color} className={className ? className : "h-14 w-auto"} />
  )
}

export default function LogoWithText(props: Props) {
  const classes = `${props.className}`

  console.log("omg classname", classes)
  return (
    <div className={classes}>
      {props.customUrl ? (
        <Image
          alt="Paragraph logo"
          src={props.customUrl}
          priority
          height={48}
          width={48}
        />
      ) : props.href ? (
        <Link href={props.href}>
          <_Logo className={classes} color={props.color} />
        </Link>
      ) : (
        <_Logo className={classes} />
      )}
    </div>
  )
}
